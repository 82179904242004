
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  onBeforeMount,
  watchEffect,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import AppService, {
  refreshSelectMedia,
  renderImageUrl,
  renderImageUrlEditor,
  showSingleMedia,
} from "@/core/services/AppService";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";
import { Partner, PartnerDefault } from "@/store/model/Partner";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "add-partner",
  props: ["id"],
  setup(props) {
    const image_editor = ref();
    const image_partner = ref();
    let x =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.getElementsByTagName("body")[0].clientWidth;
    let y =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName("body")[0].clientHeight;

    const editorConfig = {
      image_advtab: true,
      image_caption: true,
      image_description: true,
      image_uploadtab: true,
      image_class_list: [
        { title: "None", value: "" },
        { title: "No border", value: "img_no_border" },
        { title: "Green border", value: "img_green_border" },
        { title: "Blue border", value: "img_blue_border" },
        { title: "Red border", value: "img_red_border" },
      ],
      height: 1200,
      menubar: true,
      file_picker_callback: function (callback, value, meta) {
        let url = "/manager-media?type=editor";
        const instanceApi = getTinymce().activeEditor.windowManager.openUrl({
          url: url,
          title: "Quản lý media",
          width: x * 0.8,
          height: y * 0.95,
          onMessage: (api, message) => {
            //console.log("message", message, api);
          },
          onClose: async (instance, trigger) => {
            let dataImage = JSON.parse(image_editor.value);
            //console.log(dataImage);
            let image = renderImageUrlEditor(dataImage);
            let width = dataImage.width ? dataImage.width : "800";
            if (width > 1200) width = 1000;
            callback(image, {
              text: dataImage["path"],
              alt: dataImage["originalname"],
              width: width.toString(),
              height: "auto",
            });
          },
          onAction: function (instance, trigger) {
            // code for when "custom" buttons are triggered
            //console.log("instance", instance, trigger);
          },
        });
      },
      plugins: [
        "advlist autolink lists link charmap",
        "searchreplace visualblocks code fullscreen",
        "print preview anchor insertdatetime media image",
        "paste code  wordcount table advtable",
      ],
      toolbar:
        "insertfile  |formatselect | bold italic strikethrough underline |backcolor  color | subscript superscript |   alignleft aligncenter alignright alignjustify |  outdent  indent  anchor undo redo | lists   bullist numlist outdent indent | table |image|code|fullscreen",
    };
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const listDataPartnerCategory = ref([]);
    let objectData = ref<Partner>(
      JSON.parse(JSON.stringify(PartnerDefault)) as Partner
    );
    let account_current = ref();
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Tạo đối tác mới", ["Tin tức"]);
      MenuComponent.reinitialization();
      await store.dispatch(Actions.GET_ALL_PARTNERS_CATEGORY, "");
      listDataPartnerCategory.value = store.getters["listDataPartnerCategory"];
      account_current.value = store.getters["currentUser"];
      objectData.value["publish_by"] = account_current.value._id;
    });
    const rules = ref({
      name: [
        {
          required: true,
          message: "Tên đối tác không được để trống",
          trigger: "blur",
        },
      ],
    });
    const changeImageFeature = () => {
      let url = "/media?type=image-feature";
      getTinymce().activeEditor.windowManager.openUrl({
        url: url,
        title: "Quản lý media",
        width: x * 0.8,
        height: y * 0.95,
        onClose: () => {
          //console.log("onClose:", image_editor.value);
        },
        onAction: function (instance, trigger) {
          // code for when "custom" buttons are triggered
          //console.log("instance", instance, trigger);
        },
      });
    };
    const convertNameToSlug = (value) => {
      objectData.value.slug = AppService.convertSlug(value);
    };

    const selectImageMultiple = () => {
      let url = "/media?type=multi-select";
      getTinymce().activeEditor.windowManager.openUrl({
        url: url,
        title: "Quản lý media",
        width: x * 0.8,
        height: y * 0.95,
        onClose: () => {
          //console.log("onClose:", image_editor.value);
        },
        onAction: function (instance, trigger) {
          // code for when "custom" buttons are triggered
          //console.log("instance", instance, trigger);
        },
      });
    };
    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          objectData.value["publish_by"] = account_current.value["_id"];
          store
            .dispatch(Actions.ADD_PARTNER, objectData.value)
            .then(() => {
              loading.value = false;
              Swal.fire({
                text: "Cập nhật thông tin thành công",
                icon: "success",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: "Danh sách",
                cancelButtonText: "Tiếp tục chỉnh sửa",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              }).then(function (result) {
                if (result.isConfirmed) {
                  router.push({ name: "partner" });
                }
              });
            })
            .catch(() => {
              loading.value = false;
              Swal.fire({
                text: store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Thử lại!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        } else {
          loading.value = false;
          return false;
        }
      });
    };
    const isMultipleMedia = computed(() => store.getters["getIsMultiple"]);

    const media_actions = ref(["thumbnail"]);
    watch(
      () => store.state.MediaModule.actionCallback,
      (action: any, second) => {
        if (media_actions.value.includes(action)) {
          if (isMultipleMedia.value) {
            objectData.value[action] = objectData.value[action].concat(
              store.getters["getSelectMultiMedia"]
            );
          } else {
            objectData.value[action] = store.getters["getSelectMedia"];
          }
          refreshSelectMedia();
        }
      }
    );

    return {
      rules,
      submit,
      editorConfig,
      changeImageFeature,
      selectImageMultiple,
      convertNameToSlug,
      renderImageUrl,
      showSingleMedia,
      loading,
      objectData,
      listDataPartnerCategory,
      formRef,
      image_editor,
      image_partner,
    };
  },
  mounted() {
    //Event Listener for Iframe
    window.addEventListener("message", this.iframeEvent, false);
  },
  methods: {
    iframeEvent(event) {
      //Verify App Domain
      if (event.data.action == "editor") {
        this.image_editor = event.data.image;
      }
    },
  },
});
